@import '../../styles/variables';

.character {
  position: relative;
  flex: 1;
  width: 100%;
  // background-color: rgb(157, 190, 198);
  margin-top: 45px;
  font-size: 0;

  img {
    object-fit: contain;
    object-position: bottom;
    width: 100%;
    height: 100%;
    max-height: 278px;
  }

}