@import './variables';

html, body, .App, #root {
  width: 100%;
  height: 100%;
  min-width: 100%;
  height: 100%;
}

body {
  margin: 0;
  font-family: 'SpaceGrotesk';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

button, textarea {
  font-family: 'SpaceGrotesk';
  font-size: $regular;
}

p {
  margin-top: 0;
}

button {
  cursor: pointer;
}

div {
  box-sizing: border-box;
}