$primary-color: #0288D1;
$secondary-color: #9ED1C5;
$black: #000000;
$white: #FFFFFF;

$text-color: #000000;
$text-grey: #3D3D3D;
$text-light: #9BA0AB;

$xl-screen: 1200px;
$lg-screen: 1024px;
$md-screen: 900px;
$sm-screen: 700px;

$margin-xl : 50px;
$margin-lg : 30px;
$margin-md : 20px;
$margin-sm : 15px;
$margin-xs : 10px;

$regular: 14px;
$small: 12px;
$radius: 8px;

$radius-gsm: 50px;
$border-gsm: 12px;

@font-face {
	font-family: "SpaceGrotesk-Bold";
	src: url("../assets/fonts/SpaceGrotesk-Bold.ttf") format("truetype");
}

@font-face {
	font-family: "SpaceGrotesk-Medium";
	src: url("../assets/fonts/SpaceGrotesk-Medium.ttf") format("truetype");
}

@font-face {
	font-family: "SpaceGrotesk";
	src: url("../assets/fonts/SpaceGrotesk-Regular.ttf") format("truetype");
}