@import '../../styles/variables';

.end {
  width: 100%;
  height: 100%;

  .container {
    display: flex;
    height: 100%;
    max-width: 1400px;
    margin: 0 auto;
    align-items: center;
    justify-content: center;
    padding: 0 $margin-lg;
    .content {

      @media screen and (min-width: 890px) {
        flex: 0.5;
      }

      padding-right: $margin-lg;

      h1 {
        font-family: "SpaceGrotesk-Bold";
        font-size: 36px;
        font-weight: normal;
        margin: 0 0 $margin-md 0;
      }

    }
  
    .visual {
      position: relative;
      display: none;
      flex: 0.5;

      @media screen and (min-width: 890px) {
        display: block;
      }

      >div {
        position: relative;
        width: 100%;
        max-width: 610px;
        max-height: 610px;
        background-color: rgba(217, 216, 216, 0.2);
        display: flex;
        align-items: center;
        justify-content: center;
        &:after {
          content: "";
          display: block;
          padding-bottom: 100%;
        }
      }
    }
  }

  form {
    margin: $margin-xl 0;
    .question {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      margin-bottom: $margin-lg;

      @media screen and (min-width: 1350px) {
        justify-content: flex-end;
      }
      p {
        font-family: "SpaceGrotesk-Bold";
        margin: 0;
        @media screen and (min-width: 1350px) {
          text-align: right;
        }
        span {
          font-family: "SpaceGrotesk";
        }
      }
    }

    .question .choices {
      white-space: nowrap;
      margin-top: $margin-sm;
      width: 100%;
      @media screen and (min-width: 1350px) {
        width: auto;
        margin-top: 0;
        padding-left: $margin-lg;
      }
      button {
        border: solid 1px $primary-color;
        outline: none;
        cursor: pointer;
        font-family: "SpaceGrotesk-Bold";
        font-size: 16px;
        color: $primary-color;
        background-color: transparent;
        width: 121px;
        padding: 10px 0;
        border-radius: 22px;
        margin: 0 5px 0 0;
        @media screen and (min-width: 1350px) {
          margin: 0 5px;
        }
        &.selected {
          background-color: $primary-color;
          color: $white;
        }
      }
    }

    .question {
      &.input {
        position: relative;
        p {
          width: 100%;
          text-align: left;
          margin-bottom: $margin-md;
        }
        .inputEmail {
          position: relative;
          width: 100%;
          svg {
            position: absolute;
            top: 50%;
            right: 10px;
            transform: translateY(-50%);
          }
        }
      }
      textarea,
      input {
        flex: 1;
        min-width: 100%;
        border: solid 1px $primary-color;
        outline: none;
        font-family: "SpaceGrotesk-Bold";
        font-size: 16px;
        color: $primary-color;
        padding: 12px $margin-sm;
        box-sizing: border-box;
        border-radius: 21px;
      }

      textarea {
        resize: none;
        padding: $margin-sm;
      }

    }

    .question {
      button.study {
        position: relative;
        border: solid 1px $primary-color;
        outline: none;
        cursor: pointer;
        font-family: "SpaceGrotesk-Bold";
        font-size: 16px;
        color: $primary-color;
        max-width: 270px;
        background-color: transparent;
        padding: 10px 52px 10px 22px;
        border-radius: 22px;
        margin: 0 5px 0 $margin-lg;
        span {
          position: absolute;
          top: 0;
          bottom: 0;
          right: 0;
          display: block;
          width: 40px;
          border-radius: 20px;
          background-color: $primary-color;
          color: $white;
          font-size: 30px;
          svg {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
          }
        }
      }
    }

  }

  .next {
    display: flex;
    justify-content: flex-end;
    button {
      border: solid 1px transparent;
      outline: none;
      cursor: pointer;
      font-size: 16px;
      color: $primary-color;
      background-color: transparent;
      padding: 10px 0px;
      border-radius: 9px;
      text-decoration: underline;
      &:first-child {
        margin-left: auto;
      }
      &:nth-child(2) {
        font-family: "SpaceGrotesk-Bold";
        border: solid 1px $primary-color;
        background-color: $primary-color;
        color: $white;
        text-decoration: none;
        margin: 0 $margin-sm;
        min-width: 150px;
        &.disabled {
          opacity: 0.5;
          pointer-events: none;
        }
      }
    }
  }

}