@import '../../styles/variables';

@mixin fadeOutMessage {
  animation-duration: 0.4s;
  animation-name: fadeMessage;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

.thread {
  position: relative;
  background-color: $white;
  border-radius: calc($radius-gsm - $border-gsm) calc($radius-gsm - $border-gsm) 0 0;
  height: calc(100% - 375px);
  box-sizing: border-box;

  @media screen and (min-width: 500px) {
    border-radius: calc($radius-gsm - $border-gsm);
  }

  // min-height: 50%;
  padding: $margin-lg;
  // overflow-y: hidden;

  @media screen and (min-width: 960px) {
    // height: 50%;
    height: auto;
    max-height: 60%;
    min-height: 50%;
  }

  &:before {
    content: '';
    position: absolute;
    top: -30px;
    right: 7px;
    display: block;
    width: 40px;
    height: 40px;
    
    // background-color: red;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    background-image: url(./images/quote.svg);
  }

  .container {
    display: flex;
    flex-direction: column;
    // justify-content: flex-end;
    height: calc(100% - 40px);
    overflow-y: auto;

    scrollbar-width: none;  /* Firefox */
    &::-webkit-scrollbar {
      display: none;
    }

    .forceBottom {
      flex: 1;
    }

  }

  .container .content {
    display: flex;
    flex-wrap: wrap;
    width: 100%;

    .containerAnswer {
      display: flex;
      flex: 1;
      min-width: 100%;

      &.limit {
        // height: 10px;
        // background-color: red;
        &:last-child {
          // min-height: 200px;
          // flex: 1;
          margin-bottom: 20px;
        }
      }
      

      &.hide {
        display: none;
      }

      &.previous {
        button {
          pointer-events: none;
        }

        .message {
          // opacity: 0.3;
          @include fadeOutMessage;
        }

        .answer {
          // opacity: 0.3;
          pointer-events: none;
          @include fadeOutMessage;
          &:not(.selected) {
            display: none;
            visibility: hidden;
            width: 0%;
            flex: 0;
            min-width: 0%;
          }
        }

      }

      .message {
        animation-duration: 0.5s;
        animation-name: incomingMessage;
        animation-iteration-count: 1;
        transition: all 0.5s ease-in-out;
      }

      &.emotes {
        flex: 0.3;
        width: 30%;
        min-width: 40%;
        max-width: 40%;
        animation-duration: 0.5s;
        animation-name: incomingMessage;
        animation-iteration-count: 1;

        &.previous:not(.selected) {
          display: none;
          visibility: hidden;
          width: 0%;
          flex: 0;
          min-width: 0%;
        }

        img {
          margin-bottom: -5px;
        }

        p {
          font-family: "SpaceGrotesk-Bold";
          color: $text-light;
          text-align: center;
          margin: 0 0 $margin-xs 0;
        }

      }

    }
    
    div.answer {
      cursor: pointer;
      animation-duration: 0.5s;
      animation-name: incomingMessage;
      animation-iteration-count: 1;
      // transition: opacity 0.5s ease-in-out;
    }

    button.answer {
      font-family: 'SpaceGrotesk-Medium';
      color: $white;
      background-color: $primary-color;
      border: none;
      outline: none;
      padding: $margin-sm $margin-md;
      margin-left: auto;
      border-radius: $radius 0 $radius $radius;
      margin-bottom: $margin-sm;
      transition: box-shadow 0.3s ease-in-out;
      box-shadow: inset 0px 0px 0px 0px #004D77;
      animation-duration: 0.5s;
      animation-name: incomingMessage;
      animation-iteration-count: 1;

      transition: opacity 0.5s ease-in-out;

      &.selected {
        box-shadow: inset 0px 0px 0px 4px #004D77;
      }

    }
  }

  .container .nav {
    position: absolute;
    left: 50%;
    bottom: $margin-lg;
    transform: translateX(-50%);

    display: flex;
    width: calc(100% - $margin-lg);
    border-radius: 22px;
    background: $white;
    box-shadow: 0px 0px 11.25587px 2.41197px rgba(0, 0, 0, 0.12);

    >div {
      position: relative;
      display: flex;
      align-items: center;
      flex: 1;

      p {
        font-size: $small;
        color: $text-grey;
        margin: 0;
      }

      .icon {
        display: flex;
        align-items: center;
        justify-content: center;
        color: $text-light;
        border: none;
        outline: none;
        width: 42px;
        height: 42px;
        border-radius: 50%;
        font-size: 30px;
      }

      input {
        position: absolute;
        left: 40px;
        height: 40px;
        width: calc(100% - 50px);
        border: none;
        background-color: $white;
        font-size: $small;
        color: $text-color;
        outline: none;
        &::placeholder {
          color: $text-grey;
        }
      }

    }

    button {
      background: $primary-color;
      color: $white;
      border: none;
      outline: none;
      width: 42px;
      height: 42px;
      border-radius: 50%;
      font-size: 30px;
      border: solid 5px $white;
      transition: all 0.3s ease-in-out;
      cursor: pointer;

      &.disabled {
        background-color: $text-light;
        cursor: none;
        pointer-events: none;
      }

      svg {
        position: relative;
        left: -5px;
      }
    }

  }

  @keyframes incomingMessage {
    from {
      transform: translateY(20px);
      opacity: 0;
    }
  
    to {
      transform: translateY(0);
      opacity: 1;
    }
  }

  @keyframes fadeMessage {
    from {
      opacity: 1;
    }
  
    to {
      opacity: 0.3;
    }
  }

}