@import '../../styles/variables';

.visuals {
  position: relative;
  width: 100%;
  max-width: 610px;
  max-height: 610px;
  // background-color: rgb(217, 216, 216);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0;

  &:after {
    content: "";
    display: block;
    padding-bottom: 100%;
  }

  img {
    object-fit: contain;
    object-position: center;
    width: 100%;
    height: 100%;
  }

}