@import '../../styles/variables';

.steps {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;

  @media screen and (min-width: 960px) {
    justify-content: space-evenly;
  }

  .gsm {
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;

    background-color: $secondary-color;

    @media screen and (min-width: 500px) {
      height: 677px;
      min-width: 350px;
      width: 350px;
      border-radius: $radius-gsm;
      border: solid $border-gsm $black;
      margin: $margin-md;
    }

    &:after {
      position: fixed;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: -1;
      content: '';
      display: block;
      width: 532px;
      height: 540px;
      border-radius: 540px;
      background: #9ED1C5;
      flex-shrink: 0;
      filter: blur(142px);
      pointer-events: none;
    }

    .cam {
      position: absolute;
      z-index: 1;
      top: 20px;
      left: 50%;
      transform: translateX(-50%);
      background-color: #363636;
      height: 26px;
      width: 100px;
      border-radius: 13px;
    }

    .header {
      position: absolute;
      z-index: 1;
      display: flex;
      align-items: flex-start;
      flex: 1;
      margin-top: $margin-lg;
      padding: $margin-lg;

      // @media screen and (min-width: 960px) {
      //   position: relative;
      // }

      .profilePicture {
        position: relative;
        top: 3px;
        width: 36px;
        height: 36px;
        border-radius: 50%;
        background-color: $text-light;
        margin-right: $margin-xs;
      }
      
      h2 {
        font-family: "SpaceGrotesk-Bold";      
        font-size: 20px;
        margin: 0;
      }
      p {
        font-family: "SpaceGrotesk";
        color: $text-grey;
        font-size: $small;
        margin: 0;
      }
    }
  }

  .desktopVisuals {
    margin: $margin-md;
    @media screen and (max-width: 960px) {
      display: none;
    }
  }

  .mobileVisuals {
    width: 320px;
    margin: 55px auto 0 auto;
    @media screen and (min-width: 960px) {
      display: none;
    }
  }

  .character {
    @media screen and (max-width: 960px) {
      display: none;
    }
  }

}