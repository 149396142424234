@import '../../styles/variables';

.overlay-modal{
  position: fixed;
  z-index: 1002;
  top:0;
  height: 100%;
  width: 100%;
  background-color: rgba(26, 27, 66, .6);
}

// $bg-color: #F3F5F6;
// $white: #FFFFFF;
// $primary-color: #3D90B1;
// $secondary-color: #8AE5B9;
// $success: #5BC284;
// $warn: #FA5C4F;


.modal{
  position: fixed;
  z-index: 1003;
  top:50%;
  left:50%;
  transform: translate(-50%,-50%);
  background-color: $white;
  padding: $margin-md;
  border-radius: 10px;
  text-align: center;

  p {
    font-family: "SpaceGrotesk-Bold";
  }

  .close {
    position: absolute;
    top: 0;
    right: 0;
    width: 30px;
    max-width: 30px;
    height: 30px;
    border-radius: 50%;
    transform: translate(50%, -50%);
    margin: 0;
    padding: 0;
    font-size: 30px;
    cursor: pointer;
    background-color: #04122C;
    border: none;
    color: white;
    svg {
      position: relative;
      top: 15px;
      left: 50%;
      transform: translate(-50%, -50%) rotate(45deg);
    }
  }

  button {
    background-color: $primary-color;
    padding: $margin-xs $margin-sm;
    font-family: "SpaceGrotesk-Bold";
    color: $white;
    outline: none;
    border:none;
    border-radius: 5px;;
    margin: 0 5px;
  }

}
